import { Row } from 'antd';
import { useTranslation } from 'react-i18next';
import { useShiftActionContext } from '../../../Pages/Shift/Create/Context';
import ViewItem from '../ViewItem';
import { capitalize } from '../../../../utils/index';
import styles from './index.module.scss';

export default function ApplicantsSummary() {
  const { t } = useTranslation();
  const { summary, fields } = useShiftActionContext();
  const preferredApplicants = fields?.preferredApplicants
    ?.filter((item) => summary?.preferredApplicants?.includes(item.value))
    .map((item) => item.title);
  const inductions = fields?.inductions
    ?.filter((item) => summary?.inductions?.includes(item.value))
    .map((item) => item.title);

  return (
    <div className={styles.wrapper}>
      <Row gutter={[16, 0]}>
        <ViewItem text="Shift Type*" value={capitalize(summary?.shiftType)} tooltipe={t('shiftType') || ''} />

        {summary?.shiftType === 'instant' && summary?.applicant && (
          <ViewItem
            text="Assign assistant"
            value={fields?.applicants?.find((item) => item.value === summary?.applicant)?.label}
          />
        )}

        {summary?.shiftType === 'client review' && (
          <>
            <ViewItem text="Maximum Number of Assistants*" value={`${summary?.maxApplicantAmount}`} />
            <ViewItem
              text="Send to Preferred Applicant(s) first"
              value={preferredApplicants}
              tooltipe={t('shiftPreferred') || ''}
            />
          </>
        )}
      </Row>
      {inductions?.length ? (
        <Row gutter={[16, 0]}>
          <ViewItem text="Required Inductions" value={inductions} tooltipe={t('shiftInductions') || ''} />
        </Row>
      ) : null}
    </div>
  );
}
